import React, { useState, useEffect, useRef, useContext } from 'react';
import Image from 'next/image';
import { AppBar, Box, Theme, Toolbar, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useI18nContext } from '@procore/core-react';
import { List, Clear } from '@procore/core-icons';
import classnames from 'classnames';
import Cookies from 'js-cookie';
import ImageLoader from '../utils/ImageLoader';
import SearchBar from '../Search/SearchBar';
import { useStyles } from './Header.styles';
import LogInButton from '../ButtonsLinks/LogInButton';
import MyAccountButton from '../ButtonsLinks/MyAccountButton';
import AppContext from '../AppContext/AppContext';
import JoinTheNetworkButton from '../ButtonsLinks/JoinTheNetworkButton';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';
import basePath from '../../helpers/basePath';

const smViewBreakpoint = 550;
const countryIconFileNames = {
  'en-US': 'us-flag-icon.png',
  'en-CA': 'ca-flag-icon.png',
  'en-FR': 'ca-flag-icon.png',
};

interface HeaderProps {
  animated?: boolean;
  hero?: boolean;
}

export const LocalePicker = ({ setLocale }: { setLocale: Function }) => {
  const I18n = useI18nContext();
  const classes = useStyles();

  return (
    <Box className={classes.localePicker}>
      <div className={classes.localePickerTriangle} />
      <Typography className={classes.regionLabel}>
        {I18n.t('components.header.locale_picker.americas_label')}
      </Typography>
      <Box
        className={classes.locale}
        onClick={() => {
          setLocale('en-US');
        }}
        data-test-id="en-us"
      >
        <Image
          loader={ImageLoader}
          src={`/country-flag-icons/${countryIconFileNames['en-US']}`}
          alt="US Logo"
          width={24}
          height={24}
          loading="eager"
        />
        <Typography className={classes.localeName}>
          {I18n.t('components.header.locale_picker.en_us')}
        </Typography>
      </Box>
      <Box
        className={classes.locale}
        onClick={() => {
          setLocale('en-CA');
        }}
        data-test-id="en-ca"
      >
        <Image
          loader={ImageLoader}
          src={`/country-flag-icons/${countryIconFileNames['en-CA']}`}
          alt="CA Logo"
          width={24}
          height={24}
          loading="eager"
        />
        <Typography className={classes.localeName}>
          {I18n.t('components.header.locale_picker.en_ca')}
        </Typography>
      </Box>
    </Box>
  );
};

export const MobileMenu = ({
  showButtons,
  setShowButtons,
}: {
  showButtons: boolean;
  setShowButtons: Function;
}) => {
  const classes = useStyles();

  return (
    <>
      {!showButtons && (
        <List
          className={classes.menuIcon}
          size="lg"
          onClick={() => {
            setShowButtons(true);
          }}
        />
      )}
      {showButtons && (
        <Clear
          className={classes.menuIcon}
          size="lg"
          onClick={() => {
            setShowButtons(false);
          }}
        />
      )}
    </>
  );
};

export const MobileButtons = ({
  isUserLoggedIn,
}: {
  isUserLoggedIn: boolean;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.mobileButtonsContainer}>
      <Box m={0.5} className={classes.mobileButtonContainer}>
        <JoinTheNetworkButton
          className={classes.mobileButton}
          dataTrackClick="Header"
          section="Header"
        />
      </Box>
      {!isUserLoggedIn && (
        <Box m={0.5} className={classes.mobileButtonContainer}>
          <LogInButton className={classes.mobileButton} />
        </Box>
      )}
      {isUserLoggedIn && (
        <Box m={0.5} className={classes.mobileButtonContainer}>
          <MyAccountButton className={classes.mobileButton} />
        </Box>
      )}
    </Box>
  );
};

export const DesktopButtons = ({
  isUserLoggedIn,
}: {
  isUserLoggedIn: boolean;
}) => {
  const classes = useStyles();

  return (
    <>
      <Box m={0.5}>
        <JoinTheNetworkButton
          className={classes.button}
          dataTrackClick="Header"
          section="Header"
        />
      </Box>
      {!isUserLoggedIn && (
        <Box m={0.5}>
          <LogInButton className={classes.button} />
        </Box>
      )}
      {isUserLoggedIn && (
        <Box m={0.5}>
          <MyAccountButton className={classes.button} />
        </Box>
      )}
    </>
  );
};

const Header = ({ animated = false, hero = false }: HeaderProps) => {
  const { canadaLaunch } = useFlags();
  const I18n = useI18nContext();
  const { isUserLoggedIn } = useContext(AppContext);
  const classes = useStyles();
  const smView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(smViewBreakpoint)
  );

  const savedLocale = Cookies.get('locale');
  const [showLocalePicker, setShowLocalePicker] = useState(false);
  const [locale, setLocale] = useState(savedLocale || 'en-US');
  const initialLoad = useRef(true);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    if (!initialLoad.current) {
      Cookies.set('locale', locale);
      window.location.reload();
    } else {
      initialLoad.current = false;
    }
  }, [locale]);

  return (
    <AppBar
      position="sticky"
      className={classes.root}
      style={{
        height: animated ? '0px' : undefined,
        opacity: animated ? 0 : 1,
      }}
    >
      <Toolbar className={classes.toolBar}>
        <Box className={classes.logoContainer}>
          <AnalyticsWrappedComponent
            data-test-id="procore-logo"
            componentType="a"
            eventText="Clicked Procore Logo"
            eventLabel="Header"
            aria-label="Procore logo"
            className={classes.logo}
            data-track-click="Header, Logo, Procore"
            href="https://www.procore.com/"
          >
            <Image
              loader={ImageLoader}
              src="/procore_logo.svg"
              alt="Procore logo"
              width={150}
              height={20}
              loading="eager"
            />
          </AnalyticsWrappedComponent>
          <AnalyticsWrappedComponent
            data-test-id="network-link"
            componentType="a"
            eventText="Clicked Network Link"
            eventLabel="Header"
            className={classnames(classes.networkLogo, 'A11yException')}
            data-track-click="Header, Navigation, Network"
            href={basePath()}
          >
            {I18n.t('components.header.network')}
          </AnalyticsWrappedComponent>
        </Box>
        {!hero && (
          <div className={classes.searchBarContainer}>
            <SearchBar />
          </div>
        )}
        <Box className={classes.buttonsContainer}>
          {canadaLaunch && (
            <>
              <Box
                className={classnames(
                  classes.selectedLocaleContainer,
                  smView ? classes.mobileSelectedLocaleContainer : ''
                )}
                onClick={() => {
                  setShowLocalePicker(!showLocalePicker);
                }}
                data-test-id="selected-locale-container"
              >
                <Image
                  loader={ImageLoader}
                  src={`/country-flag-icons/${countryIconFileNames[locale]}`}
                  alt="Selected Locale Logo"
                  width={24}
                  height={24}
                  loading="eager"
                  data-test-id="selected-locale"
                />
              </Box>
              {showLocalePicker && <LocalePicker setLocale={setLocale} />}
            </>
          )}
          {smView && (
            <MobileMenu
              showButtons={showButtons}
              setShowButtons={setShowButtons}
            />
          )}
          {!smView && <DesktopButtons isUserLoggedIn={isUserLoggedIn} />}
        </Box>
      </Toolbar>
      {smView && showButtons && (
        <MobileButtons isUserLoggedIn={isUserLoggedIn} />
      )}
    </AppBar>
  );
};

export default Header;
