import { SvgIcon } from '@mui/material';
import React from 'react';

function LocationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2547 8.12734C16.2547 10.6724 15.0849 13.2054 13.2537 14.9219C12.3346 15.9721 10.4909 18.2557 9.26013 20.953C9.05636 21.3996 8.61823 21.7 8.12737 21.7C7.63651 21.7 7.19838 21.3996 6.99461 20.953C5.76394 18.256 3.92051 15.9725 3.00135 14.9222C1.16993 13.2057 0 10.6725 0 8.12734C0 3.63873 3.63873 0 8.12734 0C12.6159 0 16.2547 3.63873 16.2547 8.12734ZM8.12734 11.3783C9.92278 11.3783 11.3783 9.92278 11.3783 8.12734C11.3783 6.3319 9.92278 4.8764 8.12734 4.8764C6.3319 4.8764 4.8764 6.3319 4.8764 8.12734C4.8764 9.92278 6.3319 11.3783 8.12734 11.3783Z"
      />
    </SvgIcon>
  );
}

export default LocationIcon;
