import React, { useCallback, useState } from 'react';
import { Autocomplete, InputBase } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { useI18nContext } from '@procore/core-react';

import {
  fetchAutocomplete,
  getCityStateCountry,
  HereAutocompleteItem,
} from '../../data/location/autocomplete';
import LocationIcon from '../Icons/LocationIcon';
import { prettyPrintCityStateCountry } from '../../services/geo-info';
import { getGeolocation } from '../../helpers/geolocation';

const CURRENT_LOCATION = 'Current Location';

interface LocationSearchBarProps {
  callback?: (location: string) => void;
  onFocus?: () => void;
  classes?: ClassNameMap;
  inputClass?: string;
  autocompleteClass?: string;
  value: string;
  header?: boolean;
}

const LocationSearchBar = ({
  callback = () => undefined,
  onFocus = () => undefined,
  classes = {},
  inputClass = undefined,
  autocompleteClass = undefined,
  value,
  header = false,
}: LocationSearchBarProps) => {
  const I18n = useI18nContext();
  const [options, setOptions] = useState<HereAutocompleteItem[]>([]);
  const [placeholder, setPlaceholder] = useState<string>(
    I18n.t('components.search_bar.location_placeholder')
  );
  const [savedCurrentLocation, setSavedCurrentLocation] = useState<string>();

  const debouncedOptions = useCallback(
    debounce(async (input) => {
      setOptions(await fetchAutocomplete(input, true));
    }, 100),
    []
  );

  const handleCurrentLocation = async () => {
    callback('');
    setPlaceholder(
      I18n.t('components.search_bar.get_location_in_progress_placeholder')
    );
    if (savedCurrentLocation) {
      callback(savedCurrentLocation);
      setPlaceholder(I18n.t('components.search_bar.location_placeholder'));
    } else {
      const currentGeolocation = await getGeolocation();
      const currentLocation = prettyPrintCityStateCountry(
        getCityStateCountry(currentGeolocation),
        true
      );
      setSavedCurrentLocation(currentLocation);
      callback(currentLocation || '');
      setPlaceholder(I18n.t('components.search_bar.location_placeholder'));
    }
  };

  const handleLocationInputChange = async (event) => {
    if (event) {
      if (event?.target?.textContent === CURRENT_LOCATION) {
        await handleCurrentLocation();
      } else {
        debouncedOptions(event.target.value);
      }
    }
  };

  const handleChange = async (location) => {
    if (location === CURRENT_LOCATION) {
      await handleCurrentLocation();
    } else {
      callback(location);
    }
  };

  return (
    <Autocomplete
      id={header ? 'header-location-search-input' : 'location-search-input'}
      className={autocompleteClass}
      openOnFocus
      filterOptions={(options) => options}
      freeSolo
      options={[
        CURRENT_LOCATION,
        ...options.map((s) =>
          prettyPrintCityStateCountry(getCityStateCountry(s), true)
        ),
      ]}
      disableClearable
      onChange={(_, valueParameter) => handleChange(valueParameter)}
      onInputChange={handleLocationInputChange}
      renderInput={(params) => {
        const { InputProps, InputLabelProps, ...filteredParams } = params;
        return (
          <InputBase
            aria-label="Search Location"
            placeholder={placeholder}
            className={inputClass}
            startAdornment={
              <LocationIcon style={{ fontSize: '14px', marginLeft: '16px' }} />
            }
            classes={{
              root: classnames(
                classes.inputContainer,
                classes.locationInputContainer,
                classes.input
              ),
              input: classnames(classes.input, classes.locationInput),
            }}
            onFocus={onFocus}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            value={value}
            ref={params.InputProps.ref}
            {...filteredParams}
            inputProps={{
              'aria-label': 'Search Location',
              ...params.inputProps,
              value,
            }}
          />
        );
      }}
    />
  );
};

export default LocationSearchBar;
