import { HereAutocompleteItem } from '../data/location/autocomplete';
import { fetchRevGeocode } from '../data/location/revgeocode';

export const getGeolocation = async (): Promise<HereAutocompleteItem> => {
  if ('geolocation' in navigator) {
    return new Promise<HereAutocompleteItem>((resolve) => {
      const foundLocation = async (position) => {
        const lat = position?.coords?.latitude;
        const lon = position?.coords?.longitude;
        const location = await fetchRevGeocode(lat, lon);
        resolve(location);
      };
      const noLocation = () => {
        resolve(undefined);
      };
      navigator.geolocation.getCurrentPosition(foundLocation, noLocation);
    });
  }
  return Promise.resolve(undefined);
};
