import getConfig from 'next/config';
import qs from 'query-string';
import { StateType } from '../data/location/states';
import { FilterSet } from './filters';
import { CountryType } from '../data/location/countries';
import { fetchValidFilters } from '../data/filters/filters';
import { SeoListType } from '../types/seo-list-type';

const { publicRuntimeConfig } = getConfig();

const businesses = (businessSlug: string) => {
  return `${publicRuntimeConfig.basePath}/p/${businessSlug}`;
};

const trades = (
  trade?: SeoListType,
  country?: CountryType,
  state?: StateType,
  city?: string
): string => {
  let url;
  const newCity = city ? city.replace(/\s/g, '-').toLowerCase() : city;
  if (!trade?.urlKey) {
    url = `${publicRuntimeConfig.basePath}/trades`;
  } else if (newCity && state && country) {
    url = `${
      publicRuntimeConfig.basePath
    }/${country.alpha2.toLowerCase()}/${state.abbreviation.toLowerCase()}/${newCity.toLowerCase()}/${
      trade.urlKey
    }`;
  } else if (!country || !state) {
    url = `${publicRuntimeConfig.basePath}/trades/${trade.urlKey}`;
  } else {
    url = `${
      publicRuntimeConfig.basePath
    }/${country.alpha2.toLowerCase()}/${state.abbreviation.toLowerCase()}/${
      trade.urlKey
    }`;
  }
  return url;
};

const search = async (
  locationQuery?: string,
  tradeQuery?: string,
  sectorQuery?: string,
  typeQuery?: string
) => {
  let queryParams = {};

  if (locationQuery) {
    const newLocationQuery = locationQuery.match(/^\d{5}/)
      ? locationQuery.substring(0, 5)
      : locationQuery;
    queryParams = { ...queryParams, location: newLocationQuery };
  }
  if (tradeQuery || sectorQuery || typeQuery) {
    const validFilters = await fetchValidFilters(
      tradeQuery,
      sectorQuery,
      typeQuery,
      null
    );
    if (tradeQuery) {
      const trade = validFilters.trade?.[0]?.urlKey;
      if (trade) {
        queryParams = { ...queryParams, trades: trade };
      } else {
        queryParams = { ...queryParams, q: tradeQuery };
      }
    }
    if (sectorQuery && validFilters.constructionSector?.length > 0) {
      const sector = validFilters.constructionSector?.[0]?.urlKey;
      queryParams = { ...queryParams, sectors: sector };
    }
    if (typeQuery && validFilters.companyType?.length > 0) {
      const type = validFilters.companyType?.[0]?.urlKey;
      queryParams = { ...queryParams, types: type };
    }
  }

  return `${publicRuntimeConfig.basePath}/search?${qs.stringify(queryParams)}`;
};

const businessTypes = (
  typeUrlKey?: string,
  country?: CountryType,
  state?: StateType
) => {
  if (!typeUrlKey) {
    return `${publicRuntimeConfig.basePath}/business-types`;
  }

  if (!country || !state) {
    return `${publicRuntimeConfig.basePath}/business-types/${typeUrlKey}`;
  }

  return `${
    publicRuntimeConfig.basePath
  }/${country.alpha2.toLowerCase()}/${state.abbreviation.toLowerCase()}?types=${typeUrlKey}`;
};

const marketSectors = (
  sectorUrlKey?: string,
  country?: CountryType,
  state?: StateType
) => {
  if (!sectorUrlKey) {
    return `${publicRuntimeConfig.basePath}/market-sectors`;
  }

  if (!country || !state) {
    return `${publicRuntimeConfig.basePath}/market-sectors/${sectorUrlKey}`;
  }

  return `${
    publicRuntimeConfig.basePath
  }/${country.alpha2.toLowerCase()}/${state.abbreviation.toLowerCase()}?sectors=${sectorUrlKey}`;
};

const applyFilters = (
  url: string,
  filters: FilterSet,
  appendTradeParam: boolean,
  appendClassificationParam: boolean
): string => {
  if (!filters) {
    return url;
  }
  const params = [];
  if (filters.constructionSector?.length > 0) {
    params.push(
      `sectors=${filters.constructionSector.map((f) => f.urlKey).join(',')}`
    );
  }
  if (filters.companyType?.length > 0) {
    params.push(`types=${filters.companyType.map((f) => f.urlKey).join(',')}`);
  }
  if (appendTradeParam && filters.trade?.length > 0) {
    params.push(`trades=${filters.trade.map((f) => f.urlKey).join(',')}`);
  }
  if (appendClassificationParam && filters.classifications?.length > 0) {
    params.push(
      `classifications=${filters.classifications
        .map((f) => f.urlKey)
        .join(',')}`
    );
  }
  if (params.length > 0) {
    return `${url}?${params.join('&')}`;
  }
  return url;
};

const location = (
  countryAbbreviation: string,
  stateAbbreviation?: string,
  city?: string,
  trade?: string,
  classification?: string,
  filters?: FilterSet
) => {
  let url = `${
    publicRuntimeConfig.basePath
  }/${countryAbbreviation.toLowerCase()}`;
  if (stateAbbreviation && city && trade) {
    url = `${
      publicRuntimeConfig.basePath
    }/${countryAbbreviation.toLowerCase()}/${stateAbbreviation.toLowerCase()}/${city}/${trade}`;
  } else if (stateAbbreviation && city && classification) {
    url = `${
      publicRuntimeConfig.basePath
    }/${countryAbbreviation.toLowerCase()}/${stateAbbreviation.toLowerCase()}/${city}/${classification}`;
  } else if (stateAbbreviation && city) {
    url = `${
      publicRuntimeConfig.basePath
    }/${countryAbbreviation.toLowerCase()}/${stateAbbreviation.toLowerCase()}/${city}`;
  } else if (stateAbbreviation && trade) {
    url = `${
      publicRuntimeConfig.basePath
    }/${countryAbbreviation.toLowerCase()}/${stateAbbreviation.toLowerCase()}/${trade}`;
  } else if (stateAbbreviation && classification) {
    url = `${
      publicRuntimeConfig.basePath
    }/${countryAbbreviation.toLowerCase()}/${stateAbbreviation.toLowerCase()}/${classification}`;
  } else if (stateAbbreviation) {
    url = `${
      publicRuntimeConfig.basePath
    }/${countryAbbreviation.toLowerCase()}/${stateAbbreviation.toLowerCase()}`;
  }

  return applyFilters(url, filters, !trade, !classification);
};

const routes = {
  trades,
  search,
  businessTypes,
  marketSectors,
  location,
  businesses,
};

export default routes;
