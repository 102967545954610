import React, { useState } from 'react';
import { useRouter } from 'next/router';
import {
  Button,
  Divider,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material';
import { useI18nContext } from '@procore/core-react';
import classNames from 'classnames';
import SearchIcon from '../Icons/SearchIcon';
import LocationSearchBar from './LocationSearchBar';
import { FilterUrlKey } from '../../helpers/filters';
import routes from '../../helpers/routes';
import { useHeroStyles, useStyles } from './SearchBar.styles';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';
import { findCountry } from '../../data/location/countries';

interface SearchBarProps {
  routeResolver?: (
    location: string,
    key: string,
    filter?: FilterUrlKey
  ) => Promise<string>;
  hero?: boolean;
  smView?: boolean;
  tradesView?: boolean;
}
const SearchBar = ({
  routeResolver = async (location: string, key: string): Promise<string> =>
    routes.search(location, key),
  hero = false,
  smView = false,
  tradesView = false,
}: SearchBarProps) => {
  const I18n = useI18nContext();
  const heroClasses = useHeroStyles();
  const otherClasses = useStyles();
  const [classes, setClasses] = useState(hero ? heroClasses : otherClasses);
  const router = useRouter();

  let { location: queryLocation, q: queryQ } = router.query;
  queryLocation = !queryLocation ? '' : queryLocation.toString();
  queryQ = !queryQ ? '' : queryQ.toString();

  const [locationString, setLocationString] = useState(queryLocation);
  const [q, setQ] = useState(queryQ);
  const locationStringParts = locationString?.split(', ');
  const stateParamIndex = locationStringParts.length === 2 ? 0 : 1;
  const analyticsProps = {
    'Search Location': locationString || null,
    'Search Text': q || null,
    City:
      locationStringParts.length === 3
        ? locationStringParts[stateParamIndex - 1]
        : null,
    State: locationStringParts[stateParamIndex] || null,
    Country:
      findCountry(locationStringParts[stateParamIndex + 1] ?? locationString)
        ?.alpha3 || null,
  };

  return (
    <form
      className={classes.root}
      aria-label={hero ? 'main search form' : 'search form'}
      data-test-id="search-bar-form"
      role="search"
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = await routeResolver(locationString, q);
      }}
      style={
        tradesView && !smView
          ? { boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)' }
          : {}
      }
    >
      <InputBase
        aria-label="Search Trade or Sector"
        id={hero ? 'main-search-network-id' : 'search-network-id'}
        classes={{
          root: classNames(
            classes.inputContainer,
            classes.searchInputContainer
          ),
          input: classes.input,
        }}
        placeholder={I18n.t(
          'components.search_bar.search_by_trade_or_business'
        )}
        inputProps={{
          'aria-label': 'search procore construction network',
        }}
        onChange={(e) => setQ(e.target.value)}
        onFocus={() => setClasses(heroClasses)}
        value={q}
        startAdornment={
          <SearchIcon style={{ fontSize: '14px', marginLeft: '16px' }} />
        }
      />
      <Divider className={classes.divider} orientation="vertical" />
      <LocationSearchBar
        onFocus={() => setClasses(heroClasses)}
        callback={setLocationString}
        classes={classes}
        autocompleteClass={classes.autocomplete}
        value={locationString}
        header={!hero}
      />
      {smView ? (
        <AnalyticsWrappedComponent
          id="search-button-sm"
          componentType={Button}
          eventText="Clicked Search Button"
          eventLabel="Search"
          additionalEventProperties={{
            View: 'mobile',
            ...analyticsProps,
          }}
          className={classes.searchButton}
          aria-label={hero ? 'main search button' : 'search button'}
          type="submit"
          onFocus={() => setClasses(heroClasses)}
        >
          <Typography variant="h3" className={classes.searchButtonText}>
            {I18n.t('components.search_bar.search')}
          </Typography>
        </AnalyticsWrappedComponent>
      ) : (
        <AnalyticsWrappedComponent
          id="search-button-lg"
          componentType={IconButton}
          eventText="Clicked Search Button"
          eventLabel="Search"
          additionalEventProperties={{
            View: 'desktop',
            ...analyticsProps,
          }}
          aria-label={hero ? 'main search button' : 'search button'}
          type="submit"
          className={classes.searchButton}
          onFocus={() => setClasses(heroClasses)}
        >
          <SearchIcon style={{ fontSize: '14px' }} />
        </AnalyticsWrappedComponent>
      )}
    </form>
  );
};

export default SearchBar;
