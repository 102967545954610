import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    top: 0,
    zIndex: 100,
    borderBottom: `1px solid ${colors.gray.l94}`,
    background: colors.white,
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down(768)]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px 48px',
    maxWidth: '1680px',
    left: '50%',
    transform: 'translateX(-50%)',
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    alignItems: 'center',
    flexWrap: 'wrap',
    zIndex: 100,
    [theme.breakpoints.down(1440)]: {
      gap: '12px 8px',
    },
  },
  link: { textDecoration: 'none' },
  logoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    order: 1,
    [theme.breakpoints.down(435)]: {
      flexDirection: 'column',
      gap: '4px',
    },
  },
  logo: {
    marginRight: '12px',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down(435)]: {
      marginRight: '0',
    },
  },
  networkLogo: {
    textDecoration: 'none',
    color: colors.black,
    fontSize: '24px',
    fontWeight: 500,
    height: '20px',
    paddingLeft: '12px',
    borderLeft: '1px solid black',
    lineHeight: 0.8,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    [theme.breakpoints.down(435)]: {
      borderLeft: 'none',
      paddingLeft: '0',
    },
  },
  button: {
    height: '48px !important',
    minWidth: '107px',
  },
  buttonsContainer: {
    display: 'flex',
    position: 'relative',
    order: 1,
  },
  fillHeight: {
    height: '100%',
  },
  searchBarContainer: {
    flexGrow: 2,
    order: 1,
    [theme.breakpoints.down(1250)]: {
      order: 2,
      margin: '0 auto',
      width: '100%',
    },
  },
  selectedLocaleContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    top: '5px',
    height: '48px',
    width: '48px',
    marginRight: '8px',
    backgroundColor: colors.gray.l96,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  mobileSelectedLocaleContainer: {
    top: '1px',
  },
  localePicker: {
    position: 'absolute',
    top: '69px',
    left: '-120px',
    width: '288px',
    padding: '40px 45px',
    backgroundColor: colors.white,
    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.1216)',
    [theme.breakpoints.down(550)]: {
      top: '61px',
      left: '-215px',
    },
    '&::after': {
      position: 'absolute',
      top: '-15px',
      left: 'calc(50% - 12.5px)',
      height: '15px',
      width: '25px',
      background: colors.white,
      content: '""',
      boxShadow: '0px 1px 9px 0px rgba(0, 0, 0, 0.1608)',
      clipPath: 'polygon(50% 0, 0 100%, 100% 100%)',
      [theme.breakpoints.down(550)]: {
        left: 'auto',
        right: '36.5px',
      },
    },
  },
  localePickerTriangle: {
    position: 'absolute',
    top: '-15px',
    left: 'calc(50% - 13px)',
    height: '15px',
    width: '26px',
    background: colors.gray.l70,
    content: '""',
    boxShadow: '0px 1px 9px 0px rgba(0, 0, 0, 0.1608)',
    clipPath: 'polygon(50% 0, 0 100%, 100% 100%)',
    [theme.breakpoints.down(550)]: {
      left: 'auto',
      right: '36px',
    },
  },
  regionLabel: {
    marginBottom: '25px',
    fontSize: '16px',
    fontWeight: 700,
    backgroundColor: colors.white,
  },
  locale: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    cursor: 'pointer',
  },
  localeName: {
    marginLeft: '10px',
    color: colors.concrete,
    fontSize: '14px',
    fontWeight: 400,
  },
  menuIcon: {
    position: 'relative',
    top: '10px',
    cursor: 'pointer',
  },
  mobileButtonsContainer: {
    width: 'calc(100% + 48px)',
    marginLeft: '-24px',
    padding: '22px 18px 17px 18px',
    borderTop: `1px solid ${colors.gray.l94}`,
    zIndex: 50,
  },
  mobileButtonContainer: {
    width: 'calc(100% - 7px)',
  },
  mobileButton: {
    height: '48px !important',
    width: '100%',
    marginBottom: '5px',
  },
}));
