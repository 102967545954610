import { buildRevGeocodeUrl } from '../../helpers/urls';
import { HereAutocompleteItem } from './autocomplete';

const fetchRevGeocode = async (
  lat: string | number,
  lon: string | number
): Promise<HereAutocompleteItem> => {
  const result = await fetch(buildRevGeocodeUrl(lat, lon));
  const json = await result.json();
  if (json && json[0]) {
    return json[0];
  }
  return undefined;
};

export { fetchRevGeocode };
