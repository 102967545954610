export const CTA_LINK =
  'https://procore-technologies-inc.typeform.com/to/LOcJUB9E';
export const DEFAULT_PAGE_SIZE = 40;
export const DEFAULT_SEARCH_RADIUS = 25;
export const DEFAULT_SEARCH_RADIUS_KM_TO_MI = 15.53;
export const DEFAULT_SEARCH_UNIT = 'mi';
export const DEFAULT_BUSINESSES_FLOOR = 10;
export const MAX_RADIUS_MULTIPLIER = 20;
export const ELASTICSEARCH_INDEX = 'profiles';
export const ELASTICSEARCH_MAX_RETRIES = 3;
export const ELASTICSEARCH_MAX_WINDOW_SIZE = 100000;
export const URLS_PER_PAGE_SITEMAP = 50000;
