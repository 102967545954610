import { SvgIcon } from '@mui/material';
import React from 'react';

function SearchIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.69231 10.1538C3.69231 6.58523 6.58523 3.69231 10.1538 3.69231C13.7225 3.69231 16.6154 6.58523 16.6154 10.1538C16.6154 13.7225 13.7225 16.6154 10.1538 16.6154C6.58523 16.6154 3.69231 13.7225 3.69231 10.1538ZM22.6948 22.6948C23.4156 21.9739 23.4156 20.8052 22.6948 20.0843L18.517 15.9065C19.645 14.2689 20.3077 12.2898 20.3077 10.1538C20.3077 4.54708 15.7625 0 10.1538 0C4.54523 0 0 4.54708 0 10.1538C0 15.7606 4.54523 20.3077 10.1538 20.3077C12.2898 20.3077 14.2708 19.6449 15.9065 18.5169L20.0843 22.6948C20.8052 23.4156 21.9739 23.4156 22.6948 22.6948Z"
      />
    </SvgIcon>
  );
}

export default SearchIcon;
