import React from 'react';
import { Button } from '@procore/core-react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import getConfig from 'next/config';
import { NoSsr } from '@mui/material';
import { getAmplitudeDeviceId } from '../Analytics/utilities';
import { CTA_LINK } from '../../constants';
import { useStyles } from './JoinTheNetworkButton.styles';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';

const { publicRuntimeConfig } = getConfig();

const deviceId = getAmplitudeDeviceId();

type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

interface JoinTheNetworkButtonProps {
  className?: string;
  variant?: ButtonVariant;
  dataTrackClick: string;
  text?: string;
  section: string;
  additionalEventProperties?: object;
}

const getSignUpLink = (selfSignupRedirect: boolean) => {
  return selfSignupRedirect
    ? `${publicRuntimeConfig.procoreAppBaseUrl}/webclients/signup/welcome?flow=default&device_id=${deviceId}`
    : `${publicRuntimeConfig.basePath}/signup`;
};

const JoinTheNetworkButton = ({
  className,
  variant = 'primary',
  dataTrackClick,
  text = 'Sign Up',
  section,
  additionalEventProperties = {},
}: JoinTheNetworkButtonProps) => {
  const classes = useStyles();

  const { intakeForm, selfSignupRedirect } = useFlags();
  const signupLink = getSignUpLink(selfSignupRedirect);
  return (
    <NoSsr>
      <AnalyticsWrappedComponent
        componentType="a"
        eventText="Clicked Sign Up Button"
        eventLabel={section}
        additionalEventProperties={additionalEventProperties}
        aria-label="Sign Up"
        className={classes.link}
        data-track-click={`${dataTrackClick}, CTA, Join the Network`}
        href={intakeForm ? signupLink : CTA_LINK}
        target="_blank"
        rel="noreferrer"
        tabIndex={-1}
      >
        <Button
          className={classNames(className, 'A11yException')}
          variant={variant}
        >
          {text}
        </Button>
      </AnalyticsWrappedComponent>
    </NoSsr>
  );
};

export default JoinTheNetworkButton;
