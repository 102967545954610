import React from 'react';
import { Button } from '@procore/core-react';
import classNames from 'classnames';
import getConfig from 'next/config';
import { NoSsr } from '@mui/material';
import { useStyles } from './LogInButton.styles';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';

const { publicRuntimeConfig } = getConfig();

interface MyAccountButtonProps {
  className?: string;
  text?: string;
}

const MyAccountButton = ({
  className,
  text = 'My Account',
}: MyAccountButtonProps) => {
  const classes = useStyles();

  return (
    <NoSsr>
      <AnalyticsWrappedComponent
        componentType="a"
        eventText="Clicked My Account Button"
        eventLabel="Header"
        aria-label="My Account"
        className={classes.link}
        data-track-click="Header, CTA, My Account"
        href={publicRuntimeConfig.loginLink}
        target="_blank"
        rel="noreferrer"
        tabIndex={-1}
      >
        <Button
          className={classNames(className, 'A11yException')}
          variant="secondary"
        >
          {text}
        </Button>
      </AnalyticsWrappedComponent>
    </NoSsr>
  );
};

export default MyAccountButton;
