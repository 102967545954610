import React from 'react';
import { Button, useI18nContext } from '@procore/core-react';
import classNames from 'classnames';
import getConfig from 'next/config';
import { NoSsr } from '@mui/material';
import { useStyles } from './LogInButton.styles';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';

const { publicRuntimeConfig } = getConfig();

interface LogInButtonProps {
  className?: string;
}

const LogInButton = (props: LogInButtonProps) => {
  const I18n = useI18nContext();
  const classes = useStyles();

  return (
    <NoSsr>
      <AnalyticsWrappedComponent
        componentType="a"
        eventText="Clicked Log In Button"
        eventLabel="Header"
        aria-label="Login"
        className={classes.link}
        data-track-click="Header, CTA, Login"
        href={publicRuntimeConfig.loginLink}
        target="_blank"
        rel="noreferrer"
        tabIndex={-1}
      >
        <Button
          className={classNames(props.className, 'A11yException')}
          variant="secondary"
        >
          {I18n.t('components.login_button.log_in')}
        </Button>
      </AnalyticsWrappedComponent>
    </NoSsr>
  );
};

export default LogInButton;
